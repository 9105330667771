exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-1-js": () => import("./../../../src/pages/app-1.js" /* webpackChunkName: "component---src-pages-app-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-part-0-js": () => import("./../../../src/pages/part-0.js" /* webpackChunkName: "component---src-pages-part-0-js" */),
  "component---src-pages-part-1-js": () => import("./../../../src/pages/part-1.js" /* webpackChunkName: "component---src-pages-part-1-js" */),
  "component---src-pages-part-10-js": () => import("./../../../src/pages/part-10.js" /* webpackChunkName: "component---src-pages-part-10-js" */),
  "component---src-pages-part-2-js": () => import("./../../../src/pages/part-2.js" /* webpackChunkName: "component---src-pages-part-2-js" */),
  "component---src-pages-part-3-js": () => import("./../../../src/pages/part-3.js" /* webpackChunkName: "component---src-pages-part-3-js" */),
  "component---src-pages-part-4-js": () => import("./../../../src/pages/part-4.js" /* webpackChunkName: "component---src-pages-part-4-js" */),
  "component---src-pages-part-5-js": () => import("./../../../src/pages/part-5.js" /* webpackChunkName: "component---src-pages-part-5-js" */),
  "component---src-pages-part-6-js": () => import("./../../../src/pages/part-6.js" /* webpackChunkName: "component---src-pages-part-6-js" */),
  "component---src-pages-part-7-js": () => import("./../../../src/pages/part-7.js" /* webpackChunkName: "component---src-pages-part-7-js" */),
  "component---src-pages-part-8-js": () => import("./../../../src/pages/part-8.js" /* webpackChunkName: "component---src-pages-part-8-js" */),
  "component---src-pages-part-9-js": () => import("./../../../src/pages/part-9.js" /* webpackChunkName: "component---src-pages-part-9-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-pages-tech-vk-js": () => import("./../../../src/pages/tech-vk.js" /* webpackChunkName: "component---src-pages-tech-vk-js" */)
}

